<template>
  <div id="publication-list">
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <SignatureForm
        v-model="item"
        :is-form-active="isFormActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Signature') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </SignatureForm>
    </v-dialog>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Signatures") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="filters"
        :items="items"
        class="fixed-table"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Chargement des éléments')"
        :no-data-text="$t('Pas de données')"
        :no-results-text="$t('Aucun résultat')"
        hide-default-footer
        :show-select="false"
        dense fixed-header :height="tableHeight"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" />
        </template>
        <!-- name -->
        <template #item="options">
          <Row :options="options"
               :config="{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Signature' }"
               @refetch-data="fetchItems"
          >
            <template #[`item.author.full_name`]="{item}">
              <div class="d-flex align-center" v-if="item.author && item.author.id">
                <v-avatar
                  :color="item.author.avatar ? '' : 'primary'"
                  :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="30"
                >
                  <v-img
                    v-if="item.author.avatar"
                    :lazy-src="require(`@/assets/images/avatars/2.png`)"
                    :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.author.full_name) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-3">
                  <router-link
                    :to="{ name : 'author-view', params : { id : item.author_id } }"
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.author.full_name }}
                  </router-link>
                  <!--              <small>@{{ item.publicationname }}</small>-->
                </div>
              </div>
            </template>
<!--            <template #[`item.author.old_author`]="{item}">
              <v-icon
                v-if="item.author && item.author.old_author"
                small
                color="success"
              >
                {{ icons.mdiCheckCircle }}
              </v-icon>
            </template>-->
            <template #[`item.author.published`]="{item}">
              <div class="text-center">
                <v-icon
                  v-if="item.published"
                  small
                  color="success"
                >
                  {{ icons.mdiCheckCircle }}
                </v-icon>
              </div>
            </template>
            <!-- pay -->
            <template #[`item.country`]="{item}">
              <div
                v-if="item.country"
                class="d-flex align-center"
              >
                <span class="text-capitalize">{{ item.country.name }}</span>
              </div>
            </template>
            <template #[`item.is_reading`]="{item}">
              <span>
                <v-icon  v-if="item.is_reading" small color="success">
                {{ icons.mdiCheckCircle }}
              </v-icon>
              </span>
            </template>
            <template #[`item.completion_date`]="{item}">
              <span v-if="item.completion_date && !item.completion_date.includes('0000')">{{ formatDate(item.completion_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
            </template>
            <!-- status -->
            <template #[`item.send_date1`]="{item}">
              <div :style="stateColor(item.publication_colors, 'send_date')">
            <span
              v-if="item.send_date && !item.send_date.includes('0000')"
            >{{ formatDate(item.send_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
                <v-btn
                  v-else
                  x-small
                  color="primary"
                  text
                  @click="item.signature_date?null:openDialog({...item, action: 'send_date',activity_date: item.send_date,date_column:'send_date', 'activity_date_label': $t('Date envoi'), info: $t('Confirmez-vous qu\'une demande de signature a été envoyée ?') })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <template #[`item.resend_date1`]="{item}">
              <div v-if="item.send_date" :style="stateColor(item.publication_colors, 'resend_date')">
                <template >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <span
                      style="cursor: pointer"
                      v-bind="attrs"
                      @click="item.signature_date?null:openDialog({...item, action: 'resend_date',activity_date: item.resend_date,date_column:'resend_date', 'activity_date_label': $t('Date relance'), info: $t('Confirmez-vous de relancer la demande de signature ?') })"
                      v-on="on"
                    ><span v-if="item.resend_date && !item.resend_date.includes('0000')">{{ formatDate(item.resend_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'resend_date').length === 0)">{{ $t('Cliquez ici pour relancer la demande') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in  item.publication_records  && item.publication_records.filter(el=>el.type === 'resend_date').length === 0"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>


                    </template>
                  </v-tooltip>
                </template>
                <v-tooltip
                  v-if="!item.resend_date || item.resend_date.includes('0000')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn

                      x-small
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="item.signature_date?null:openDialog({...item, action: 'resend_date',activity_date: item.resend_date,date_column:'resend_date', 'activity_date_label': $t('Date relance'), info: $t('Confirmez-vous la demande de signature a été relancée ?') })"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiCheckboxMarkedCircleOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cliquez ici pour relancer la demande') }}</span>

                </v-tooltip>
              </div>
            </template>
            <template #[`item.signature_date1`]="{item}">
              <div v-if="item.send_date" :style="stateColor(item.publication_colors, 'signature_date')">
                <template >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                    style="cursor: pointer"
                    :class="{'text-red': item.state < 0 }"
                    v-bind="attrs"
                    @click="openDialog({...item, action: 'signature',activity_date: item.signature_date,date_column:'signature_date', 'activity_date_label': $t('Date signature'), info: $t('Confirmez-vous de marquer comme signé ?') })"
                    v-on="on"
                  ><span v-if="item.signature_date && !item.signature_date.includes('0000')">{{ formatDate(item.signature_date || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span></span>
                    </template>
                    <span v-if="!item.publication_records ||  (item.publication_records.filter(el=>el.comment && el.type === 'signature').length === 0)">{{ $t('Cliquez ici pour mettre à jour') }}</span>
                    <template v-else>
                      <div>
                        <p
                          v-for="precord in item.publication_records.filter(el=>el.type === 'signature')"

                          :key="precord.id"
                        >
                          <span class="mr-2">{{ precord.user?precord.user.code:'' }}</span>
                          <span class="mr-2" v-if="precord.date_record">{{ formatDate(precord.date_record || null, { month: 'numeric', day: 'numeric', year: 'numeric' }) }}</span>
                          <span class="mr-2">{{ precord.comment }}</span>
                        </p>
                      </div>
                    </template>
                  </v-tooltip>
                </template>
                <v-btn
                  v-if="!item.signature_date || item.signature_date.includes('0000')"
                  x-small
                  color="primary"
                  text
                  @click="openDialog({...item, action: 'signature',activity_date: item.signature_date,date_column:'signature_date', 'activity_date_label': $t('Date signature'), info: $t('Confirmez-vous de marquer comme signé ?') })"
                >
                  <v-icon color="primary">
                    {{ icons.mdiCheckboxMarkedCircleOutline }}
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <template #[`item.status`]="{item}">
              <v-chip
                v-if="item.status && item.status.id"
                small
                :color="item.status.color"
                :class="`${item.status.color}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.status.name }}
              </v-chip>
              <!--          <manuscript-status-chip
                :object="item"
                @refetch-data="fetchManuscripts"
              />-->
            </template>

            <!-- actions -->
            <template #[`item.actions`]="{item}">
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    :to="{name:'publication-view',params:{id:item.id}}"
                  >
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                      <span>{{ $t('Détails') }}</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="openDialog(item)">
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                      <span>{{ $t('Modifier') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="confirmDelete(item)">
                    <v-list-item-title>
                      <v-icon
                        size="20"
                        class="me-2"
                      >
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                      <span><span>{{ $t('Supprimer') }}</span></span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </Row>
        </template>

      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckboxMarkedCircle,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import store from '@/store'
import itemStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import SignatureForm from '@/views/apps/publications/SignatureForm'
import HeaderFilters from '@/components/HeaderFilters'
import axios from '@axios'
import ability from '@/plugins/acl/ability'
import TableRow from '@/components/TableRow'
import Row from "@/components/Row";

export default {
  components: {
    Row,
    TableRow,
    HeaderFilters,
    SignatureForm,
    RowsPerPage,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      stateFilter,
      publicationStates,
      item,
      items,
      signatureTableColumns: tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      tabFilter,
      totalItems,
      loading,
      options,
      publicationTotalLocal,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems,tableHeight,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
    } = controller()


    const superiors = ref([])
    const assistants = ref([])

    tabFilter.value = 'Signature'
    filters.value = tableColumns.filter(ele => !ele.value || ability.can('view_' + ele.value, 'Text'))

    axios
      .get('/listWorkers', {
        params: {
          term: '', type: 'text_cover', user: JSON.parse(localStorage.getItem('userData')).id || null, rowsPerPage: 100,
        },
      })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistants.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistants.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistants.value
        }
      })

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Publication', value: 'publication' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Actif', value: 1 },
      { title: 'Inactif', value: 0 },
    ]
    store
      .dispatch('app-publication/fetchCountries', { per_page: 500 })
    store
      .dispatch('app-publication/fetchSources', { per_page: 500 })
    store
      .dispatch('app/fetchGenres', { per_page: 500 })
    store
      .dispatch('app/fetchFormats', { per_page: 500 })
    axios
      .get('/statuses', { params: { per_page: 500, type: 'publication' } })
      .then(response => {
        const indexH = filters.value.findIndex(ele => ele.value === 'status')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))

    //stateFilter.value = [publicationStates.value.CREATED_STATE, publicationStates.value.SEND_STATE, publicationStates.value.RESEND_STATE]

    filters.value = tableColumns.filter((ele) => !ele.value || ability.can('view_' + ele.value, 'Signature'))

    return {
      item,
      items,
      superiors,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      tabFilter,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      publicationTotalLocal,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      resolvePublicationRoleVariant,
      resolvePublicationRoleIcon,
      resolvePublicationStatusVariant,
      resolvePublicationStatusName,
      resolvePublicationTotalIcon,
      fetchItems,tableHeight,
      assistants,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCheckboxMarkedCircleOutline,
        mdiCheckboxMarkedCircle,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
